export default {
  translations: {
    menu: {
      benefits: {
        title: "Flash Benefits",
        api: "Programmatic Access",
        activeCards: "Cards Management",
        deposits: "Deposits",
        employees: "Benefits Checkout",
        points: {
          title: "Points",
          customBenefits: "Custom Benefits",
          policies: "Policies",
          policiesAssignment: "Policies Assignment",
        },
        payrollLoan: "Payroll Loan",
        yourBenefits: "Benefits Management",
        simpleAssignment: "Benefits assignment",
        placedOrders: "Placed Orders",
      },
      cards: "Cards",
      employees: "Team",
      expenses: {
        title: "Flash Expense",
        corporateCard: {
          title: "Corporate Card",
          home: "Cards Management",
          management: "Balances Management",
        },
        externalCards: "Third party cards",
        expenses: "Expenses",
        advances: "Advances",
        approvals: "Approvals",
        accounting: {
          title: "Accounting",
          accountability: "Accountability",
          payables: "Company payables",
          advances: "Advance",
        },
        customizations: "Customizations",
        analytics: "Insights e reports",
        vendors: "Vendors",
        travel: {
          title: "Travel",
          list: "Requests",
          search: "Quotation",
          approvals: "Approvals",
          monitoring: "Monitoring",
          reports: "Reports",
        },
      },
      finance: {
        title: "Financial",
        payments: "Payments",
        balance: "Flash Cash",
      },
      home: "Home",
      myFlash: {
        title: "My Flash",
        cards: "My Cards",
        wallet: "My Wallet",
        statement: "My Statement",
      },
      marketplace: "Partners and discounts",
      people: {
        title: "Flash People",
        employmentLifecycle: {
          title: "Manage Flows",
          hiring: "Hiring",
          termination: "Resignation",
          recognition: "Recognition",
        },
        manageFlows: {
          flows: "Manage Flows",
          hiring: "Hiring",
          resignation: "Resignation",
          recognition: "Recognition",
        },
        lms: {
          courses: "Courses",
          myCourses: "My Courses",
          manageCourses: "Manage Courses",
        },
        engagement: {
          engagement: "Engagement",
          manageCommunications: "Manage Communications",
          manageSurveys: "Manage Surveys",
          mySurveys: "My Surveys",
        },
        orgchart: "Orgchart",
        analytics: "Insights",
        performance: {
          performance: "Performance",
          manageCriterials: "Manage Evaluative Criteria",
          manageCycles: "Manage Cycles",
          myCycles: "My Cycles",
          manageEvaluations: "Manage Evaluations",
          manageFeedbacks: "Manage Feedbacks",
          myFeedbacks: "My Feedbacks",
          manageIdp: "Manage IDPs",
          myIdp: "My IDP",
        },
        timeAndAttendance: {
          timeAndAttendance: "Time and Attendance",
          clockin: "Clock In",
          timeSheet: "My Timesheet",
          myVacations: "My Vacations",
          activities: "Activities",
          approvals: "Approvals",
          timeSheets: "Manage timesheets",
          events: "Manage events",
          vacations: "Manage vacations",
          bankHours: "Comp time",
          exportData: "Export data",
          preferences: "Preferences",
          workshift: "My workshift",
          integrations: "Integrations",
        },
      },
      reports: "Reports",
      settings: "Settings",
      documentManagement: {
        title: "Manage documents",
      },
    },
    menuItem: {
      newItem: "New",
    },
  },
};
